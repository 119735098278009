@import url(https://fonts.googleapis.com/css?family=Lato:ital,wght@0,400;0,700;1,400&display=swap);
body {
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.anticon {
  vertical-align: 0.2rem !important;
}
